import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Button from '../components/common/Button';
import ContentContainer from '../components/common/ContentContainer';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

interface HeroSectionProps {}
const HeroSection: React.FC<HeroSectionProps> = props => {
  return (
    <section className="flex flex-col">
      <section className="mt-8 w-3/4 self-center">
        <h1
          className="text-primary font-bold text-center mb-8 text-4xl"
          id="our-story"
        >
          Our Mission
        </h1>

        <p className="text-gray-500 text-xl self-center w-full smw-2/3 m-auto">
          Advancing Literacy is committed to working side-by-side with educators
          in and out of classrooms. We design transformative professional
          development to bring literacy success - and joy - to all children. We
          are equally dedicated to collaborating with researchers and theorists
          to innovate, invent, and incorporate new knowledge into classroom
          practices and literacy leadership. In every endeavor, we prioritize
          equity and access for children, educators, and families.
        </p>
        <section className="ml-10">
          <p className="text-gray-500 text-xl self-center w-full smw-2/3 m-auto mt-4">
            We believe in the power of <b>literacy</b> to be a force for good in
            the world. We strive to be part of building a world where
            anti-oppressive education is the norm, all student identities are
            celebrated, and all children’s voices are heard.
          </p>
          <p className="text-gray-500 text-xl self-center w-full smw-2/3 m-auto mt-4">
            We believe in the power of <b>teachers</b>. We aim to innovate
            pedagogies and provision resources so that teachers can be part of
            communities beyond their own borders, and do work that exists
            because it is in coalition with others.
          </p>
          <p className="text-gray-500 text-xl self-center w-full smw-2/3 m-auto mt-4">
            We believe in the significance of{' '}
            <b>theory and practice converging</b>. There is always new research,
            and we strive to study diverse voices and fields of research, and to
            integrate that knowledge with innovative and courageous classroom
            pedagogies.
          </p>
        </section>
      </section>
    </section>
  );
};

interface WhatWeDoProps {}
const WhatWeDo: React.FC<WhatWeDoProps> = props => {
  return (
    <section className="flex flex-col">
      <section className="mt-8 w-3/4 self-center">
        <h2
          className="text-blue-200 text-4xl font-bold mb-10 text-center"
          id="what-we-do"
        >
          What We Do
        </h2>

        <p className="mb-6 text-blue-100 text-xl">
          At Advancing Literacy, we work as a think tank with teachers and
          school leaders to develop, pilot, and continually revise methods and
          ideas for implementation of best-practice literacy curriculum. We help
          teachers and school leaders throughout the world teach in
          state-of-the-art ways, and we join them in studying their students’
          growth, adapting instruction based on that field research. Our work
          draws on a deep knowledge of reading and writing development, of
          standards, and a commitment to being responsive to students’
          interests, strengths and needs.
        </p>
        <p className="mb-6 text-blue-100 text-xl">
          At Teachers College, we offer in depth institutes, conference days,
          study groups and especially classroom-based professional development.
          Advancing Literacy leads scores of mini-institutes a year on topics
          ranging from equity and inclusion to teaching research skills and
          argument reading.
        </p>
      </section>
    </section>
  );
};

interface MissionSectionProps {}
const MissionSection: React.FC<MissionSectionProps> = props => {
  return (
    <section className="flex flex-col bg-primary">
      <div className="w-3/4 flex self-center flex-col mt-10">
        <h2
          className="text-blue-200 text-4xl font-bold mb-10 text-center"
          id="our-mission"
        >
          Our Mission and Vision
        </h2>
        <div className="mb-10">
          <p className="mb-6 text-blue-100 text-2xl text-center">
            We help schools develop avid and skilled readers, writers, and
            inquirers. Our goal is to develop future generations of expert,
            passionate, and critical thinkers - young people who use literacy to
            speak out and to live as engaged, curious, justice-seeking citizens
            in the world. Our literacy work strives to affirm identities,
            histories, and cultures, to amplify student voices, and to increase
            equity and justice.
          </p>
        </div>
      </div>
    </section>
  );
};

interface ResultsSectionProps {
  imageOne: any;
  imageTwo: any;
  imageThree: any;
}
const ResultsSection: React.FC<ResultsSectionProps> = props => {
  return (
    <section className="flex flex-col">
      <div className="w-3/4 flex self-center flex-col mt-10">
        <h2
          className="text-primary text-4xl font-bold mb-2 text-center"
          id="how-we-know-it-works"
        >
          How We Know It Works
        </h2>
        <section className="mt-10 self-center w-full mb-10">
          <div className="flex">
            <div className="w-1/2">
              <div className="flex flex-col items-end mr-2">
                <div className="w-96">
                  <Img
                    className="rounded-tl-lg mb-2 w-full"
                    fluid={props.imageOne.childImageSharp.sizes}
                  />
                </div>
                <div className="w-96">
                  <Img
                    className="rounded-bl-lg w-full"
                    fluid={props.imageTwo.childImageSharp.sizes}
                  />
                </div>
              </div>
            </div>
            <div className="w-1/2">
              <div className="h-full">
                <Img
                  className="rounded-tr-lg rounded-br-lg w-96 h-full object-fill"
                  fluid={props.imageThree.childImageSharp.sizes}
                />
              </div>
            </div>
          </div>
        </section>
        <div className="mb-10">
          <p className="mb-6 text-gray-600 text-xl">
            The results of our reading, writing, and phonics workshops are
            visible in classrooms and schools around the globe. The work
            students do - the essays they write, the TedTalks they present, the
            petitions they send, the book club conversations they participate in
            - are our results.
          </p>
          <p className="mb-6 text-gray-600 text-xl">
            Student growth can also be measured through performance on state
            standardized ELA exams. A 2021 study by the American Institute of
            Research (AIR) studied students in 229 schools in New York City and
            Georgia and found that those who have learned within Advancing
            Literacy's curriculum, the Units of Study, outperform students in
            both locations who were taught using other curricula. This was true
            for students in all subgroups, including students with IEPs and
            English Language Learners, and the results were more dramatic the
            longer a school participated in Advancing Literacy's curriculum.
          </p>
          <p className="mb-6 text-gray-600 text-xl">
            Many schools working with Advancing Literacy and with Units of Study
            have been honored as Blue Ribbon recipients, as Beat-the-Odds
            Schools, and as mentor sites.
          </p>
          <div className="flex justify-center text-xl">
            <Link
              to={`/resources/case-studies-and-reports`}
              className="text-blue-500 hover:underline"
            >
              <Button
                text="Learn more about our results in partner schools"
                className="text-center"
              />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

interface AffiliationSectionProps {}
const AffiliationSection: React.FC<AffiliationSectionProps> = props => {
  return (
    <section className="flex flex-col">
      <div className="w-3/4 flex self-center flex-col mt-10">
        <h2
          className="text-primary text-4xl font-bold mb-10 text-center"
          id="how-we-do-it"
        >
          How We Do It
        </h2>
        <div className="mb-10">
          <p className="mb-6 text-gray-600 text-xl">
            Through research, curriculum development, and critical attention to
            methods of instruction, we strive to provide all students with
            opportunities for powerful and joyful engagement with literacy. Our
            work centers on young people learning skills in phonics,
            comprehension, and the craft of writing, transferring their learning
            across the curriculum and throughout their lives.
          </p>
          <p className="mb-6 text-gray-600 text-xl">
            Advancing Literacy supports young people, teachers, and families in
            order to develop future generations of passionate readers and
            writers. We strive to create and support teaching that is
            anti-racist and anti-oppressive, teaching that fosters engagement,
            empathy, and inclusivity.
          </p>
          <p className="mb-6 text-gray-600 text-xl">
            We approach this work by coaching teachers and children as they
            work, by designing powerful, critical, active curriculum, and by
            continuously learning from collaborators, researchers, educators and
            children.
          </p>
          <p className="mb-6 text-gray-600 text-xl">
            We recognize our continual need to learn and grow. For us,
            professional development is for us all, and it needs to be
            career-long, approached with courage, humility, a readiness to
            rethink, and a commitment to a world where justice for all becomes a
            reality.
          </p>
        </div>
      </div>
    </section>
  );
};

interface AboutProps {}
const OurMission: React.FC<AboutProps> = props => {
  const data = useStaticQuery(graphql`
    {
      imageOne: file(relativePath: { eq: "images/about-us-2.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 600) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      imageTwo: file(relativePath: { eq: "images/about-us-1.jpeg" }) {
        childImageSharp {
          sizes(maxWidth: 600) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      imageThree: file(relativePath: { eq: "images/about.png" }) {
        childImageSharp {
          sizes(maxWidth: 600) {
            ...GatsbyImageSharpSizes
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="About" />
      <ContentContainer>
        <HeroSection />
      </ContentContainer>
    </Layout>
  );
};

export default OurMission;
